<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-main ftw600">积分设置</div>
			
			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<div>
						<div class="ft14 ftw500 cl-info">积分余额抵扣设置</div>
						<div class="mt16">
							<div class="flex alcenter">
								<div class="ft14 ftw500 cl-main">100积分</div>
								<div class="ft14 ftw500 cl-notice mr10 ml10">=</div>
								<div style="position: relative;">
									<a-input-number v-model="form.integral_exchange_balance" style="width: 130px;" :precision="2" :min="0" placeholder="请输入"/>
									<div class="setting-integral-addon">元</div>
								</div>
							</div>
						</div>
					</div>
					<div class="">
						<div class="ft14 ftw500 cl-info">积分抵扣余额上限比</div>
						<div class="mt16">
							<div class="flex alcenter">
								<div style="position: relative;">
									<a-input-number v-model="form.integral_deduction_balance_limit_rate" style="width: 160px;" :precision="2" :min="0" placeholder="请输入比例"/>
									<div class="setting-balance-addon">%</div>
								</div>

								<div class="ft14 ftw500 cl-info ml16">如比例是50%，买单金额为100元，那么会员最多可用积分抵扣50元,不填写默认100%</div>
							</div>
						</div>
					</div>
					<div class="mt24">
						<div class="ft14 ftw500 cl-info">消费送积分比例</div>
						<div class="mt16">
							<div class="flex alcenter">
								<div style="position: relative;">
									<a-input-number v-model="form.recharge_give_integral_rate" style="width: 130px;" :precision="2" :min="0" placeholder="请输入"/>
									<div class="setting-integral-addon">%</div>
								</div>
								
								<div class="ft14 ftw500 cl-info ml16">如比例是80%，那么消费100元，即送会员80积分（余额支付不返积分，余额充值自动返）</div>
							</div>
						</div>
					</div>
					<div class="mt24">
						<div class="ft14 ftw500 cl-info">送积分的支付方式</div>
						<div class="mt16">
							<div class=" alcenter">
								<a-checkbox-group v-model="form.give_pay_type"   :options="options" >

								</a-checkbox-group>
								<br/>

								<br />
								<div class="ft14 ftw500 cl-info ml16">允许选中的支付方式支付送积分</div>
							</div>
						</div>
					</div>
					
					<div class="mt24">
						<div class="ft14 ftw500 cl-info">是否过期</div>
						<div class="mt16">
							<a-radio-group v-model="form.is_expire">
								<a-radio :value="1">
									是
								</a-radio>
								<a-radio :value="0">
									否
								</a-radio>
							</a-radio-group>
						</div>
					</div>
					
					<div class="mt24" v-if="form.is_expire==1">
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info">过期时间</div>
							<div class="ft14 ftw400 cl-notice">(默认积分有效期为1年，不过期为10年)</div>
						</div>
						<div class="mt16 flex">
							<div  style="position: relative;">
								<a-input-number v-model="form.valid_year" style="width:300px;" :precision="0" :min="0" placeholder="默认积分有效期为1年，不过期为10年"/>
								<div class="setting-integral-addon">年</div>
							</div>
						</div>
					</div>
					
					
					
					<div class="mt30">
						<a-button type="primary" :loading="confirmLoading" @click="save()">保存</a-button>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				loading:false,
				confirmLoading:false,
                options:[],

				form:{
					integral_exchange_balance:'',
					recharge_give_integral_rate:'',
                    integral_deduction_balance_limit_rate:'',
					is_expire:1,
					valid_year:'',
                    give_pay_type:[],

				}
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showIntegralSetting').then(res=>{
					this.form=res.detail;
					this.options=res.type_lists;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			save(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveIntegralSetting',{
					integral_exchange_balance:this.form.integral_exchange_balance,
					recharge_give_integral_rate:this.form.recharge_give_integral_rate,
                    integral_deduction_balance_limit_rate:this.form.integral_deduction_balance_limit_rate,
					is_expire:this.form.is_expire,
					valid_year:this.form.valid_year,
                    give_pay_type:this.form.give_pay_type,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
		}
	}
</script>

<style>
	.setting-integral-addon{
		position: absolute;
		top:0;
		right:26px;
		height: 32px;
		line-height: 32px;
		
		font-size: 14px;
		font-weight: 500;
		color: #A6AEC2;
	}
	.setting-balance-addon{
		position: absolute;
		top:0;
		right:26px;
		height: 32px;
		line-height: 32px;

		font-size: 14px;
		font-weight: 500;
		color: #A6AEC2;
	}
</style>
